import React, { useRef, useEffect, Fragment } from "react";
import { range } from "ramda";
//import useWindowSize from "../components/hooks/useWindowSize";
import d3 from "../utils/d3-importer";
//import "../style/charts.css";
//import * as d3 from "d3";

// function to create new colours for the picking
let nextCol = 1;
let colourToNode = {};

function genColor() {
  const ret = [];
  if (nextCol < 16777215) {
    ret.push(nextCol & 0xff);
    ret.push((nextCol & 0xff00) >> 8);
    ret.push((nextCol & 0xff0000) >> 16);

    nextCol += 1;
  }
  const col = "rgb(" + ret.join(",") + ")";
  return col;
}

function DataBlock({ data, dimensions = {} }) {
  const container = useRef(null);
  const tooltipContainer = useRef(null);
  //  const [windowWidthSize, windowHeightSize] = useWindowSize();
  const isArray = Array.isArray(data);
  const { key, value, color } = data;
  const vizData = [];
  let totalBlocks = isArray ? 0 : value;
  if (isArray) {
    data.forEach(element => {
      totalBlocks = +element.value;
      range(element.value).forEach(function(el) {
        vizData.push({
          value: el.value,
          color: element.color,
          key: element.key
        });
      });
    });
  } else {
    range(0, value).forEach(function(el) {
      vizData.push({
        value: el,
        color: color,
        key: key
      });
    });
  }

  useEffect(() => {
    drawCanvas();
  }, []);

  function drawCanvas() {
    const windowWidthSize = 1600;
    const width =
      windowWidthSize > 1500
        ? Math.floor(windowWidthSize * 0.45)
        : windowWidthSize > 1000
        ? Math.floor(windowWidthSize * 0.55)
        : windowWidthSize > 700
        ? Math.floor(windowWidthSize * 0.8)
        : Math.floor(windowWidthSize * 0.9);
    // const height = Math.floor(width / 11) * (Math.floor(totalBlocks / 200) + 1);
    //const width = dimensions.width;
    const height = dimensions.height;
    const mainCanvas = d3
      .select(container.current)
      .append("canvas")
      .classed("mainCanvas", true)
      .attr("width", width)
      .attr("height", height);
    // const hiddenCanvas = d3
    //   .select(container.current)
    //   .append("canvas")
    //   .classed("hiddenCanvas", true)
    //   .attr("width", width)
    //   .attr("height", height);
    // const tooltip = d3.select(tooltipContainer.current);
    const customBase = document.createElement("custom");
    const custom = d3.select(customBase);
    const groupSpacing = 4;
    const cellSpacing = 2;
    const cellSize =
      Math.floor((width * groupSpacing) / 100) - cellSpacing - 21;
    const join = custom.selectAll("custom.rect").data(vizData);

    // data bind
    const enterSel = join
      .enter()
      .append("custom")
      .attr("class", "rect")
      .attr("x", function(d, i) {
        const x0 = Math.floor(i / 100) % 10,
          x1 = Math.floor(i % 10);
        return groupSpacing * x0 + (cellSpacing + cellSize) * (x1 + x0 * 10);
      })
      .attr("y", function(d, i) {
        const y0 = Math.floor(i / 1000),
          y1 = Math.floor((i % 100) / 10);
        return groupSpacing * y0 + (cellSpacing + cellSize) * (y1 + y0 * 10);
      })
      .attr("width", 0)
      .attr("height", 0);
    join
      .merge(enterSel)
      .attr("width", cellSize)
      .attr("height", cellSize)
      .attr("fillStyle", d => d.color)
      .attr("fillStyleHidden", function(d) {
        if (!d.hiddenCol) {
          d.hiddenCol = genColor();
          colourToNode[d.hiddenCol] = d;
        }
        return d.hiddenCol;
      });

    // draw canvas
    function draw(canvas, hidden) {
      const ctx = canvas.node().getContext("2d");
      ctx.clearRect(0, 0, width, height);
      const elements = custom.selectAll("custom.rect");
      elements.each(function(d, i) {
        var node = d3.select(this);
        ctx.fillStyle = hidden
          ? node.attr("fillStyleHidden")
          : node.attr("fillStyle");
        ctx.fillRect(
          node.attr("x"),
          node.attr("y"),
          node.attr("width"),
          node.attr("height")
        );
      });
    }

    draw(mainCanvas, false);

    // mainCanvas.on("mousemove", function() {
    //   draw(hiddenCanvas, true);
    //   const mouseX = d3.event.layerX || d3.event.offsetX;
    //   const mouseY = d3.event.layerY || d3.event.offsetY;

    //   const hiddenCtx = hiddenCanvas.node().getContext("2d");

    //   // Now to pick the colours from where our mouse is then stringify it in a way our map-object can read it
    //   const col = hiddenCtx.getImageData(mouseX, mouseY, 1, 1).data;
    //   //console.log(col); ERROR most of values are 0,0,0
    //   const colKey = "rgb(" + col[0] + "," + col[1] + "," + col[2] + ")";
    //   const nodeData = colourToNode[colKey];

    //   if (nodeData) {
    //     tooltip
    //       .style("opacity", 1)
    //       .style("top", d3.event.pageY + 5 + "px")
    //       .style("left", d3.event.pageX + 5 + "px")
    //       .html(nodeData.key);
    //   } else {
    //     tooltip.style("opacity", 0);
    //   }
    // });

    // canvas.on("mousemove", function() {
    //   var mouseX = d3.event.layerX || d3.event.offsetX;
    //   var mouseY = d3.event.layerY || d3.event.offsetY;
    //   var nodeData = find(nodes, n => {
    //     if (
    //       mouseX < parseInt(n.x) + parseInt(n.w) &&
    //       mouseX > parseInt(n.x) &&
    //       mouseY < parseInt(n.y) + parseInt(n.h) &&
    //       mouseY > parseInt(n.y)
    //     ) {
    //       console.log("true");
    //       return true;
    //     }
    //     return false;
    //   });
    //   if (nodeData) {
    //     tooltip
    //       .style("opacity", 0, 8)
    //       .style("top", d3.event.pageY + 5 + "px")
    //       .style("left", d3.event.pageX + 5 + "px")
    //       .html("hello");
    //   } else {
    //     tooltip.style("opacity", 0);
    //   }
    // });
  }
  return (
    <Fragment>
      <div ref={container}></div>
      {/* <div ref={tooltipContainer}></div> */}
    </Fragment>
  );
}

export default DataBlock;
