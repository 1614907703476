import { select, event } from "d3-selection";
import { treemap, hierarchy } from "d3-hierarchy";
import { timeInterval, timeYear, timeMonth } from "d3-time";
import { scaleTime, scaleLinear, scaleOrdinal } from "d3-scale";
import { json } from "d3-fetch";
import { schemeSet3 } from "d3-scale-chromatic";
import { axisBottom, axisLeft, axisRight } from "d3-axis";
import { extent, max, min } from "d3-array";
import { timeParse, timeFormat } from "d3-time-format";
import { pie, arc } from "d3-shape";
import { entries } from "d3-collection";

export default {
  event: event,
  entries: entries,
  pie: pie,
  arc: arc,
  timeMonth: timeMonth,
  scaleTime: scaleTime,
  scaleLinear: scaleLinear,
  axisBottom: axisBottom,
  axisLeft: axisLeft,
  axisRight: axisRight,
  select: select,
  extent: extent,
  interval: timeInterval,
  timeYear: timeYear,
  timeFormat: timeFormat,
  timeParse: timeParse,
  max: max,
  min: min,
  treemap: treemap,
  hierarchy: hierarchy,
  scaleOrdinal: scaleOrdinal,
  json: json,
  schemeSet3: schemeSet3
};
