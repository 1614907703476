import React from "react";
import DataBlock from "../components/DataBlock";
import useTranslations from "components/hooks/useTranslations";

function Blocks() {
  const tr = useTranslations();
  const dataSet = [
    {
      key: tr("Facebook posts"),
      value: 1222,
      color: "#60323D",
      width: 180
    },
    {
      key: tr(`Tweets`),
      value: 2607,
      color: "#6E6586",
      width: 280
    },
    {
      key: tr(`Articles`),
      value: 384,
      color: "#40A3B1",
      width: 100
    },
    {
      key: tr(`Youtube Videos`),
      value: 74,
      color: "#6DDB9A",
      width: 100
    }
  ];

  return (
    <div>
      {dataSet.map(d => (
        <div
          key={d.value}
          css={{
            maxHeight: "100%"
          }}
        >
          <h3
            css={{
              marginTop: 0
            }}
          >
            {d.key}
          </h3>
          <DataBlock data={d} dimensions={{ width: 800, height: d.width }} />
        </div>
      ))}
    </div>
  );
}

export default Blocks;
