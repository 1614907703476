// custom typefaces
import "typeface-tajawal";
import "@openfonts/tajawal_arabic";
import { includes, without } from "ramda";

export const shouldUpdateScroll = (args, page) => {
  // A workaound, update the scroll on all pages excluding data pages
  // see Gatsby.js #1719
  //const windowWidth = window.innerWidth;
  const isDataPage = includes("data", window.location.pathname);
  return isDataPage ? true : false;
};

export const onInitialClientRender = () => {
  window.___MNEMONIC_INITIAL_RENDER_COMPLETE = true;
};

export { wrapPageElement } from "./src/context/locale-context";
