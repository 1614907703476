module.exports = {
  en: {
    default: true,
    path: `en`,
    locale: `en-US`,
    siteLanguage: `en`
  },
  ar: {
    path: `ar`,
    locale: `ar-AR`,
    siteLanguage: `ar`,
    ogLanguage: `ar_AR`
  }
};
